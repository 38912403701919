import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"
import main from "../images/actionsculturelles/actionsculturelles_main.jpg"
import exposition from "../images/actionsculturelles/exposition_card.jpg"
import workshop1 from "../images/actionsculturelles/workshop1_card.jpg"
import workshop2 from "../images/actionsculturelles/workshop2_card.jpg"

const CreationsPage = ({ data }) => {

  return (
    <>
      <Layout>
        <div className="page-wrapper creations">
            <div className="main-image">
              <img src={main}/>
            </div>
         
            <h1>WORKSHOPS</h1>

            <div className="list-crea">
              <Link to="/actionsculturelles/workshops/danseia" className="list-crea-item">
                <img src={workshop1} />
                <h2>Danse & IA</h2>
                <h3>2023</h3>
                <div className="description">
                  <p>"Expérimentez autour des dispositifs de la compagnie: dansez avec l'IA.</p>
                </div>
              </Link>
              
              <Link to="/actionsculturelles/workshops/floorwork" className="list-crea-item">
                <img src={workshop2} />
                <h2>Floorwork</h2>
                <h3>2023</h3>
                <div className="description">
                  <p>"Immersion dans le sol par une approche hybride urbaine et contemporaine."</p>
                </div>
              </Link>


            </div>

            <h1>EXPOSITIONS</h1>
            
            <div className="list-crea">
              <Link to="/actionsculturelles/expositions/ianow" className="list-crea-item">
                <img src={exposition} />
                <h2>L'IA</h2>
                <h3>2023</h3>
                <div className="description">
                  <p>"Découvrez où nous en sommes."</p>
                </div>
              </Link>
            </div>
          
            <div>
                <Link className="button" href="/contact">Contact</Link>
            </div>
          
          </div> 
      </Layout>
    </>
  )
}

export default CreationsPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
